
import gallery from './modules/gallery';
import imageList from './modules/imageList';
import maps from './modules/maps';
import navigation from './modules/navigation';
import slider from './modules/slider';
new WOW().init();
imageList();
gallery();
maps();
navigation();
slider();
